// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Images/Banner.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Images/Banner Mobile.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* MAIN BANNER */
#page-header-banner4{
  height: 57.3%;
  width: 100%;
    /* margin-bottom: 2%;     */
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
}


@media (max-width: 767px) {
    #page-header-banner4 {
      height: 38.6%;
      background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    }
  }
`, "",{"version":3,"sources":["webpack://./src/FoldersFourthLandingPage/MainPage4.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,aAAa;EACb,WAAW;IACT,2BAA2B;IAC3B,yDAA4C;IAC5C,sBAAsB;IACtB,2BAA2B;AAC/B;;;AAGA;IACI;MACE,aAAa;MACb,yDAAmD;IACrD;EACF","sourcesContent":["/* MAIN BANNER */\r\n#page-header-banner4{\r\n  height: 57.3%;\r\n  width: 100%;\r\n    /* margin-bottom: 2%;     */\r\n    background-image: url('./Images/Banner.jpg');\r\n    background-size: cover;\r\n    background-position: center;\r\n}\r\n\r\n\r\n@media (max-width: 767px) {\r\n    #page-header-banner4 {\r\n      height: 38.6%;\r\n      background-image: url('./Images/Banner Mobile.png');\r\n    }\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
