import React from 'react'
import './MainPage.css'
import MainBanner from './Images/Banner.png'
import BannerContent from './BannerContent/BannerContent'
import DownPageContent from './DownPageContent/DownPageContent'

function MainPage1_2() {
  return (
    <div id="initial-page-wrapper">
        <div id="page-header-banner1">
            {/* Banner content */}
            <BannerContent pageId="detoque1" secondIndicator="detoque1" tracker="false1.1" thirdIndicator="detoque1" pageType="casino" structure="detoque-new" text_indicator="det5"/>
        </div>
        <div id="page-header-banner-2">
            <DownPageContent pageId="detoque1" pageType="casino" tracker="false1.1" structure="detoque-new" text_indicator="det5"/>
        </div>
    </div>
  )
}

export default MainPage1_2