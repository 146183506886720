import React, { Suspense,lazy  } from 'react'
import './MainPage.css'
import MainBanner from './Images/Banner.png'
import BannerContent from './BannerContent/BannerContent'
// import DownPageContent from './DownPageContent/DownPageContent'
const DownPageContent = lazy(() => import('./DownPageContent/DownPageContent'));


function MainPage() {
  return (
    <div id="initial-page-wrapper">
        <div id="page-header-banner1">
            {/* Banner content */}
            <BannerContent pageId="detoque1" secondIndicator="detoque1" tracker="false1" thirdIndicator="detoque1" pageType="casino" structure="detoque-new" text_indicator="det5"/>
        </div>
        <div id="page-header-banner-2">
            {/* <DownPageContent pageId="detoque1" structure="detoque-new" tracker="false1" pageType="casino" text_indicator="det5"/> */}
            <Suspense fallback={<div>Loading...</div>}>
                {/* Lazy-loaded DownPageContent component */}
                <DownPageContent pageId="detoque1" structure="detoque-new" tracker="false1" pageType="casino" text_indicator="det5"/>
            </Suspense>
        </div>
    </div>
  )
}

export default MainPage