import React from 'react'
import './MainPage7.css'
import BannerContent from '../FoldersFirstLandingPage/BannerContent/BannerContent'
import DownPageContent from '../FoldersFirstLandingPage/DownPageContent/DownPageContent'

function MainPage7() {
  return (
    <div id="initial-page-wrapper">
            <div id="page-header-banner7">
                    <BannerContent pageId="detoque1" secondIndicator="detoque1" thirdIndicator="detoque1" fourthIndicator="detoque7"/>
            </div>
            <div id="page-header-banner-2">
                    <DownPageContent pageId="detoque1" />
            </div>
        </div>
  )
}

export default MainPage7