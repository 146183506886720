import React from 'react'
import './MainPage8.css'
import BannerContent from '../FoldersFirstLandingPage/BannerContent/BannerContent'
import DownPageContent from '../FoldersFirstLandingPage/DownPageContent/DownPageContent'

function MainPage8() {
  return (
    <div id="initial-page-wrapper">
            <div id="page-header-banner8">
                    <BannerContent pageId="detoque1" secondIndicator="detoque3" thirdIndicator="detoque5" fourthIndicator="detoque8" tracker="false2" structure="detoque-new" text_indicator="det5"/>
            </div>
            <div id="page-header-banner-2">
                    <DownPageContent pageId="detoque1" />
            </div>
    </div>
  )
}

export default MainPage8