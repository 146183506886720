import React from 'react'
import './DownPageContent.css'

import VisaImg from '../SponsorsImg/Visa.png'
import MastercardImg from '../SponsorsImg/Mastercard.png'
import Evolution from '../SponsorsImg/Evolution.png'
import TomHorn from '../SponsorsImg/Tom Horn.png'
import PlayNgo from '../SponsorsImg/PlayNgo.png'
import VivoGame from '../SponsorsImg/VivoGaming.png'
import BetSoft from '../SponsorsImg/BetSoft.png'
import PragmaticPlay from '../SponsorsImg/PragmaticPlay.png'
import Netent from '../SponsorsImg/netent-logotype.png'

import newFirstRule from '../../FoldersFirstLandingPage/Images/new rule 1.png'

import newSecondRule from '../../FoldersFirstLandingPage/Images/new rule 2.png'
import newThirdRule from '../../FoldersFirstLandingPage/Images/new rule 3.png'
import daleFirstRule from '../../Dale/DaleFirstPage/Images/daleFirstRule.png'
import daleSecondRule from '../../Dale/DaleFirstPage/Images/daleSecondRule.png'
import daleThirdRule  from '../../Dale/DaleFirstPage/Images/daleThirdRule.png'

import newSecondRulCasino from '../../FoldersFirstLandingPage/Images/new_rule2-casino.png'

import {ReactComponent as ThreeStepInfographic2} from '../../FoldersFirstLandingPage/MobileOnlyImg/new steps 2.svg'
import {ReactComponent as ThreeStepInfographic2Casino} from '../../FoldersFirstLandingPage/MobileOnlyImg/new steps 2.svg'

// import {ReactComponent as DaleThreeStepInfographic2} from '../../Dale/DaleFirstPage/Images/daleThreeStepInformation.svg'


const sponsorsImg = 
    [
        {
            id: 0,
            image: MastercardImg
        },
        {
            id:1,
            image: VisaImg

        },
        {
            id: 2,
            image: PlayNgo
        },
        {
            id:3,
            image: Evolution
        },
        {
            id: 4,
            image: PragmaticPlay
        },
        ,
        {
            id:5,
            image: Netent
        },
        {
            id: 6,
            image: TomHorn
        },
        {
            id:7,
            image: BetSoft
        },
        {
            id: 8,
            image: VivoGame
        },

    ]
  


function DownPageContent({ pageId,pageType,structure,tracker, text_indicator}) {
  return (
    <div id="down-page-content-external-wrapper"
        style={pageId==="dale1" ? 
        {background: 'radial-gradient(40.48% 71.46% at 50% 50%, #0010C2 0%, #001E75 100%)'}
        : {background: 'transperant'}
        }
    >
                        <div id="down-page-content-initial-wrapper">
                        {/* RULES */}
                        <div id="down-page-content-register-steps">
                                <div id="down-page-content-register-step1-wrapper">
                                    <img src={pageId==="dale1" ? daleFirstRule : newFirstRule} alt='dale-img'/>                               
                                </div>
                                <div id="down-page-content-register-step2-wrapper">
                                    <img src={pageId === "dale1" ? daleFirstRule : (pageType === "casino" ? newSecondRulCasino : newSecondRule)} alt='dale-img'/>
                                </div>
                                <div id="down-page-content-register-step3-wrapper">
                                    <img src={pageId==="dale1" ? daleThirdRule : newThirdRule} alt='dale-img'/>
                                </div>
                        </div>
                      
                        <div id="down-page-content-sponsors-component">
                            <div id="down-page-content-sponsors-component-firstRow">
                                {sponsorsImg.filter(e => e.id < 10).map((item, id)=> {
                                    return( 
                                        <div 
                                        key={id}
                                        id="down-page-content-sponsors-component-sponsorBox">
                                            <img src={item.image} alt='item-img'/>
                                        </div>
                                    )
                                })}
                            

                            </div>
                            <div id="down-page-content-sponsors-component-firstRow">
                                {sponsorsImg.filter(e => e.id >= 10).map((item, id)=> {
                                    return( 
                                        <div key={id} id="down-page-content-sponsors-component-sponsorBox">
                                            <img src={item.image} alt='item-img'/>
                                        </div>
                                    )
                                })}
                            

                            </div>    

                        </div>
                        {/* BIG TEXT COMPONENT */}
                        <div id="down-page-big-component-external-wrapper">

                            <div id="down-page-big-third-row">
                           
                            </div>
                            <div id="down-page-big-fourth-row">
                            </div>
                            <div id="down-page-big-fifth-row">
                            </div>
                            <div id="down-page-big-fifth-row">
                            </div>
                            <div id="down-page-big-sixth-row">
                                
                            </div>
                            <div id="down-page-big-seventh-row">
                         
                            </div>
                            <div id="down-page-big-eight-row">

                            </div>
                            <div id="down-page-big-ninth-row">

                            </div>
                            <div id="down-page-big-tenth-row">

                            </div>
                            <div id="down-page-big-fifth-row">
                            </div>
                            <div id="down-page-big-eleventh-row">

                            </div>
                        </div>
                        <div id="down-component-final-row">
                               
                        </div>

                </div>
                {/* MOBILE */}
                <div id="down-page-content-initial-wrapper-mobile">
                    <div id="down-page-content-firstRow-text-mobile"
                    style={pageId==="dale1" ? {backgroundColor: 'transparent'} : {backgroundColor: '#0C0C0C'}}
                    >
                     
                          { pageType==="casino" ? <ThreeStepInfographic2Casino/> : <ThreeStepInfographic2/>}
                        
                    </div>
                    <div 
                    style={structure==='detoque-new' ? { display: 'flex', justifyContent: 'center', alignItems: 'center'} : {display: 'none'}}
                    id="down-page-content-sponsors-wrapper-mobile-buton-za-nav">
                        <a id='banner-content-vis-elements-secondPart-btn2'
                        style={structure==="detoque-new" ? {backgroundImage: 'linear-gradient(90deg , #FFCB27 , #F46100)', width: '82%', height: '66%', fontSize: '6.5vw', textDecoration: 'none'}:{ backgroundImage: ' linear-gradient(90deg , #26F596, #0499F2)',textDecoration: 'none'} }
                        href={(() => {
                            const trackerURL =
                                tracker === "true"
                                    ? 'https://detoque.com/en/homepage?register=true&btag=s_lpryqmnaygfrlbrxeovgpcypn&affid=63967&campaign_id=1740&utm_source=deposita'
                                    : tracker === "false2"
                                    ? "https://go.aff.detoqueaffiliates.com/tc8zatq2?campaign_id=2827&utm_source=sport"
                                    : tracker === "false2.1"
                                    ? "https://go.aff.detoqueaffiliates.com/rxvmyugu?campaign_id=2793&utm_source=sport"
                                    : tracker === "false10"
                                    ? 'https://trk.myperfect2give.com/417289e5-d82b-4439-aaf6-45ef682ba45c?zoneid={zoneid}&campaignid={campaignid}&bannerid={bannerid}&zone_type={zone_type}&user_activity={user_activity}&subzone_id={subzone_id}&cost={cost}&visitor_id=${SUBID}'
                                    : tracker === "false11"
                                    ? 'https://go.aff.detoqueaffiliates.com/59tm0924&afp={clickid}&afp1=26'
                                    : tracker === "false1"
                                    ? "https://go.aff.detoqueaffiliates.com/c9798cz5?campaign_id=2793&utm_source=casino"
                                    : tracker === "false1.1"
                                    ? "https://go.aff.detoqueaffiliates.com/mwrrwouc?campaign_id=2827&utm_source=casino"
                                    : 'https://detoque.com/index/register';
                            return trackerURL;
                        })()}
                        
                        >
                                {structure==='detoque-new' ? 
                                    (text_indicator==='det2' ?  'ÚNETE A LA ACCIÓN' 
                                    : text_indicator==='det3' ? 'RECLAMAR AQUÍ'
                                    : text_indicator==='det5' ? 'RECLAMAR BONO'
                                    : ' ACTIVAR AQUÍ')  
                                    : 'DEPOSITA'}
                        </a>
                    </div>
                        
                        <div id="down-page-content-sponsors-wrapper-mobile"
                            style={pageId==="dale1" ? {backgroundColor: 'transparent'} : {backgroundColor: '#0C0C0C'}}

                        >
                            <div id="down-page-content-sponsors-wrapper-mobile-first-column">
                            {sponsorsImg.filter(e => e.id % 2 === 0).map((item, id)=> {
                                        return( 
                                            <div key={id} id="down-page-content-sponsors-wrapper-mobile-field">
                                                <img src={item.image} alt='item-img' />
                                            </div>
                                        )
                                    })}
                            

                            </div>
                            <div id="down-page-content-sponsors-wrapper-mobile-second-column">
                                {sponsorsImg.filter(e => e.id % 2 === 1).map((item, id)=> {
                                        return( 
                                            <div key={id} id="down-page-content-sponsors-wrapper-mobile-field">
                                                <img src={item.image} alt='item-img'/>
                                            </div>
                                        )
                                    })}
                            </div>

                        </div>
                        <div id="terminos-and-condisiones-mobile"
                            style={pageId==="dale1" ? {backgroundColor: 'transparent'} : {backgroundColor: '#0C0C0C'}}
                        >
                                <div id="terminos">
                                    <span>
                                    </span>
                                  
                                </div>
                                <div id="aplicons">
                                    <span>
                                       
                                    </span>
                                   
                                </div>

                        </div>
                </div>
    </div>
    
  )
}

export default DownPageContent