import React from 'react'
import BannerContent from '../FoldersFirstLandingPage/BannerContent/BannerContent'
import DownPageContent from '../FoldersFirstLandingPage/DownPageContent/DownPageContent'
import './MainPage5.css'

function MainPage5_3() {
  return (
    <div id="initial-page-wrapper">
            <div id="page-header-banner5">
                    <BannerContent pageId="detoque1" secondIndicator="detoque3" thirdIndicator="detoque5" tracker="false2" structure="detoque-new" text_indicator="det5"/>
            </div>
            <div id="page-header-banner-2">
                    <DownPageContent structure="detoque-new" pageId="detoque1" text_indicator="det5"/>
            </div>
    </div>
  )
}

export default MainPage5_3