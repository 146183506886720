import React from 'react'
import './MainPage3.css'
import DownPageContent from '../FoldersFirstLandingPage/DownPageContent/DownPageContent'
import BannerContent from '../FoldersFirstLandingPage/BannerContent/BannerContent'

function MainPage3_3() {


  return (
        <div id="initial-page-wrapper">
            <div id="page-header-banner3">
                    <BannerContent pageId="detoque1" secondIndicator="detoque3" tracker="false1.1" thirdIndicator="detoque3" pageType="casino" structure="detoque-new" text_indicator="det3"/>
            </div>
            <div id="page-header-banner-2">
                    <DownPageContent pageId="detoque1" structure="detoque-new" tracker="false1.1" pageType="casino" text_indicator="det3"
                    // secondIndicator="detoque3"
                    />
            </div>
        </div>
  )
}

export default MainPage3_3