import React from 'react'
import BannerContent from '../FoldersFirstLandingPage/BannerContent/BannerContent'
import DownPageContent from '../FoldersFirstLandingPage/DownPageContent/DownPageContent'
import './MainPage6.css'

function MainPage6() {
  return (
    <div id="initial-page-wrapper">
            <div id="page-header-banner6">
                    <BannerContent pageId="detoque2" secondIndicator="detoque2" thirdIndicator="detoque6" tracker="false2" structure="detoque-new"/>
            </div>
            <div id="page-header-banner-2">
                    <DownPageContent  pageId="detoque2"/>
            </div>
    </div>
  )
}

export default MainPage6