import React from 'react'
import './MainPage4.css'
import BannerContent from '../FoldersFirstLandingPage/BannerContent/BannerContent'
import DownPageContent from '../FoldersFirstLandingPage/DownPageContent/DownPageContent'

function MainPage4() {
  return (
    <div id="initial-page-wrapper">
            <div id="page-header-banner4">
                    <BannerContent pageId="detoque2" secondIndicator="detoque2" thirdIndicator="detoque4" pageType="casino"/>
            </div>
            <div id="page-header-banner-2">
                    <DownPageContent  pageId="detoque2" pageType="casino"/>
            </div>
    </div>
  )
}

export default MainPage4