import React, { useState } from 'react';
import './Step1Component.css';

function Step1Component({ couponCode ,setStep, onEmailSubmit  }) {
  // State variables for inputs and errors
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  // Function to handle form submission
  const handleSubmit = () => {
    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(prevEmailError => {
      if (!email || !emailRegex.test(email)) {
        return 'Invalid email address';
      } else {
        return '';
      }
    });
  
    // Validate username
    setUsernameError(prevUsernameError => {
      if (!username || username.length < 3) {
        return 'Username must be at least 3 characters long';
      } else if (/^\d+$/.test(username)) {
        return 'Username must contain at least one non-digit character';
      } else {
        return '';
      }
    });
  
    // Validate password
    setPasswordError(prevPasswordError => {
      if (!password || password.length < 3) {
        return 'Password must be at least 3 characters long';
      } else {
        return '';
      }
    });
  
    // Change step to 2
    setEmailError(prevEmailError => {
      if (!prevEmailError && !usernameError && !passwordError) {
        onEmailSubmit(email, username, password);
        setStep(2);
      }
      return prevEmailError;
    });
  };
  

  return (
    <div id='step-one-wrapper'>
      <div id='input-wrapper'>
        <label id='input-title' style={{fontSize: '.9rem'}}>Coupon Code</label>
        <input
          style={{border: '1px solid #24ef9d' }}
          id='input'
          placeholder='Code'
          type='text'
          value={couponCode}
        />
      </div>
      <div id='input-wrapper'>
        <label id='input-title'>Email</label>
        <input
          id='input'
          placeholder='Email'
          type='text'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label id='input-error-prompt'>{emailError}</label>
      </div>
      <div id='input-wrapper'>
        <label id='input-title'>Username</label>
        <input
          id='input'
          placeholder='Username'
          type='text'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        {/* You can add validation for username here if needed */}
        <label id='input-error-prompt'>{usernameError}</label>
      </div>
      <div id='input-wrapper'>
        <label id='input-title'>Password</label>
        <input
          id='input'
          placeholder='Password'
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <label id='input-error-prompt'>{passwordError}</label>
      </div>
      <div id='input-wrapper-btn'>
        <button onClick={handleSubmit}>Next</button>
      </div>
    </div>
  );
}

export default Step1Component;
