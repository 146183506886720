import React from 'react'
import './MainPage2.css'
import BannerContent from '../FoldersFirstLandingPage/BannerContent/BannerContent'
import DownPageContent from '../FoldersFirstLandingPage/DownPageContent/DownPageContent'


function MainPage2_1() {


  return (
    <div id="initial-page-wrapper">
            <div id="page-header-banner2"> 
                    <BannerContent pageId="detoque2" secondIndicator="detoque2" thirdIndicator="detoque2" tracker="false2" structure="detoque-new" text_indicator="det2"/>
            </div>
            <div id="page-header-banner-2">
                    <DownPageContent structure="detoque-new" pageId="detoque2" text_indicator="det2"/>
            </div>
        </div>
  )
}

export default MainPage2_1