import React from 'react'
import './MainPage10.css'
import MainBanner from './Images/Banner.png'
import BannerContent from '../FoldersFirstLandingPage/BannerContent/BannerContent'
import DownPageContent from '../FoldersFirstLandingPage/DownPageContent/DownPageContent'

function MainPage10() {
  return (
    <div id="initial-page-wrapper">
        <div id="page-header-banner10">
            {/* Banner content */}
            <BannerContent pageId="detoque1" secondIndicator="detoque1" thirdIndicator="detoque1" tracker="false10" pageType="casino"/>
        </div>
        <div id="page-header-banner-2">
            <DownPageContent pageId="detoque1" pageType="casino"/>
        </div>
    </div>
  )
}

export default MainPage10