import React, {useState} from 'react'
import './PopUp.css'
import Step1Component from './PopUpComponents/Step1Component'
import Step2Component from './PopUpComponents/Step2Component'

function PopUp({couponCodeFromParent}) {
    const [step, setStep] = useState(1)
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [gender, setGender] = useState('');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [country, setCountry] = useState('');
    const [currency, setCurrency] = useState('');
    const [cellPhone, setCellPhone] = useState('');
    const [yearOfBirth, setYearOfBirth] = useState('');
    const [receiveNewsletters, setReceiveNewsletters] = useState(true); 




    const handleEmailSubmit = (emailValue, usernameValue, passValue ) => {
        setEmail(emailValue);
        setUsername(usernameValue);
        setPassword(passValue);
    };

    const handleSecondStepSubmit = (gender,fname,lname,country,currency,cellPhone,yearOfBirth,receiveNewsletters) => {
        setFname(fname)
        setLname(lname)
        setCountry(country)
        setCurrency(currency)
        setCellPhone(cellPhone)
        setYearOfBirth(yearOfBirth)
        setGender(gender)
        setReceiveNewsletters(receiveNewsletters)

        if(email && username && password && gender && fname && lname && country && currency && cellPhone && yearOfBirth !== '') {
            // All necessary fields are set, proceed with registration
                const requestBody = {
                    "app-key": "$2a$08$246w1EHB1BiASaLRPqFyTufwcjIZUla.SRmNVjlao5K.Bga.zNX/2",
                    "app-params": {
                        "_username": username,
                        "_password": password,
                        "_currency_id": currency=== "Guatemalan Quetzal" ? 1 : currency=== "Honduran Lempira" ? 60 : 2,
                        "_email": email,
                        "_registration_email": 1,
                        "_local_time": 0,
                        "_locale": "en_EN",
                        "_gender": gender,
                        "_title": "mr",
                        "_fname": fname,
                        "_lname": lname,
                        "_phone": 123456789, // Assuming this is not a useState value, you can replace it with your actual logic
                        "_cell_phone": parseInt(cellPhone), // Assuming this is not a useState value, you can replace it with your actual logic
                        "_birthday": formatDateString(yearOfBirth),
                        "_country_id": country=== "Guatemala" ? 91 : 97,
                    }
                };
        
                fetch('https://api2.delasport.com/v1/json/member/create', {
                    method: 'POST',
                    headers: {
                        'accept': 'application/json',
                        'API-ACCESS': 'uh0vfCZrKvUU3PyXa2PZdhY6yn1jPTYFQTQ0ANMtAFvta8wsffPApULdSWRgLe7nB7JbzzDTyGgj0dhhJLPA2LVcZbzfAVf6mNMF',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(requestBody)
                })
                .then(response => response.json())
                .then(data => {
                    // Handle response data
                    console.log(data);
                    window.location.href = 'https://detoque.com/en?quick_deposit=true';

                })
                .catch(error => {
                    // Handle errors
                    console.error('Error:', error);
                });
        } else {
            console.log("Please fill in all required fields.");
        }
    }

   


    function formatDateString(dateString) {
        // Split the date string into its components
        const parts = dateString.split('-');
        
        // Ensure month and day have leading zeros if necessary
        const month = parts[1].padStart(2, '0');
        const day = parts[2].padStart(2, '0');
        
        // Return the formatted date string
        return `${parts[0]}-${month}-${day}`;
    }    

  return (
    <div id='popup-inner-content-wrapper'>
        <div id='steps-wrapper'>
            <div id="step-initial-wrapper" 
            // onClick={()=>setStep(1)}
            >
                <div id="step-1-wrapper"
                style={{border: '2px solid #24ef9d', width: '2.25rem', height: '2.25rem', color: '#24ef9d'}}
                >
                    1
                </div>
            </div>
            <div 
            id="step-initial-wrapper"
            // onClick={()=>setStep(2)}
            >
                <div id="step-1-wrapper"
                style={step===2 ? {border: '2px solid #24ef9d', width: '2.25rem', height: '2.25rem', color: '#24ef9d'} : {}}
                >
                    2
                </div>
            </div>
        </div>
        {step===1 ? <Step1Component couponCode={couponCodeFromParent} setStep={setStep}
        onEmailSubmit={handleEmailSubmit}
        /> : <Step2Component
        onSecondStepSubmit={handleSecondStepSubmit}
        />}
    </div>
  )
}

export default PopUp