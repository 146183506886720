import React, { useState, useEffect, useRef } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './BannerContent.css'
import Logo from '../Images/DeToque_1 1.png'
import DaleLogo from '../../Dale/DaleFirstPage/Images/Logo.png'
import axios from 'axios';
import { useGeolocation } from "@uidotdev/usehooks";
import BtnPlay2 from '../../Dale/DaleFirstPage/Images/btn_play.png'
import CloseBtn from '../BannerContent/PopUpComponents/Icon ion-close-round.png'
import { ReactComponent as BtnPlay} from '../../Dale/DaleFirstPage/Images/button-play.svg'
import {ReactComponent as BannerDale} from '../../Dale/DaleFirstPage/Images/Banner-desktop.svg'
import {ReactComponent as BannerDaleMobile} from '../../Dale/DaleFirstPage/Images/banner-dale-mobile.svg'

//GREEN THEMES
    //DESKTOP 
            //CASINO
                    //DOLARS - added 
                        //  import {ReactComponent as GreenDolars} from '../../FoldersFirstLandingPage/Images/new texts/casino dolars.svg'
                    //GUATEMALA - added
                        //  import {ReactComponent as GreenGuatemala} from '../../FoldersFirstLandingPage/Images/new texts/casino guatemala.svg'
                    //HONDURAS
                        // import {ReactComponent as GreenHonduras} from '../../FoldersFirstLandingPage/Images/new texts/casino honduras.svg'
                    //NICARAGUA - added
                    import {ReactComponent as GreenNiceragua} from '../../FoldersFirstLandingPage/Images/new texts/casino nicaragua.svg'
            //SPORT
                    //DOLARS
                        import {ReactComponent as GreenDolarsSport} from '../../FoldersFirstLandingPage/Images/new texts/sport_dolars2.svg'
                        //detoque2
                        import {ReactComponent as GreenDolarsSportNew} from '../../FoldersFirstLandingPage/Images/new texts/sport_dolars2_new.svg'
                        //detoque3
                        import {ReactComponent as GreenDolarsMSportNew3} from '../../FoldersFirstLandingPage/Images/new texts/newHeaders/detoque3.svg'

                        //detque5
                        import {ReactComponent as GreenDolarsMSportNew5} from '../../FoldersFirstLandingPage/Images/new texts/newHeaders/detoque5.svg'
                        //detque6
                        import {ReactComponent as GreenDolarsMSportNew6} from '../../FoldersFirstLandingPage/Images/new texts/newHeaders/detoque6.svg'

                        //GUATEMALA - added
                         import {ReactComponent as GreenGuatemalaSport} from '../../FoldersFirstLandingPage/Images/new texts/sports_guatemala.svg'
                         //detoque2
                         import {ReactComponent as GreenGuatemalaSportNew} from '../../FoldersFirstLandingPage/Images/new texts/newHeaders/Guatemala-detoque2.svg'
                         //detque3
                         import {ReactComponent as GreenGuatemalaSportNew3} from '../../FoldersFirstLandingPage/Images/new texts/newHeaders/Guatemala-detoque3.svg'

                         //detoque5   
                         import {ReactComponent as GreenGuatemalaSportNew5} from '../../FoldersFirstLandingPage/Images/new texts/newHeaders/Guatemala-detoque5.svg'
                        //detoque6  
                        import {ReactComponent as GreenGuatemalaSportNew6} from '../../FoldersFirstLandingPage/Images/new texts/newHeaders/Guatemala-detoque6.svg'
                         
                        //HONDURAS
                         import {ReactComponent as GreenHondurasSport} from '../../FoldersFirstLandingPage/Images/new texts/sports_honduras.svg'
                          //detoque2
                         import {ReactComponent as GreenHondurasSportNew} from '../../FoldersFirstLandingPage/Images/new texts/newHeaders/Honduras-detoque2.svg'
                        //detoque3
                        import {ReactComponent as GreenHondurasSportNew3} from '../../FoldersFirstLandingPage/Images/new texts/newHeaders/Honduras-detoque3.svg'
                        
                         //detoque5
                        import {ReactComponent as GreenHondurasSportNew5} from '../../FoldersFirstLandingPage/Images/new texts/newHeaders/Honduras-detoque5.svg'
                        //detoque5
                        import {ReactComponent as GreenHondurasSportNew6} from '../../FoldersFirstLandingPage/Images/new texts/newHeaders/Honduras-detoque6.svg'

                      //NICARAGUA- added
                         import {ReactComponent as GreenNiceraguaSport} from '../../FoldersFirstLandingPage/Images/new texts/sports nicaragua.svg'
    //MOBILE
            //CASINO
                    //DOLARS
                    //  import {ReactComponent as GreenDolarsM} from '../../FoldersFirstLandingPage/Images/new texts/mobiles/casino dolars.svg'
                    //GUATEMALA
                    // import {ReactComponent as GreenGuatemalaM} from '../../FoldersFirstLandingPage/Images/new texts/mobiles/Casino-Guatemala.svg'
                    //HONDURAS
                    // import {ReactComponent as GreenHondurasM} from '../../FoldersFirstLandingPage/Images/new texts/mobiles/Casino-Honduras.svg'
                    //NICARAGUA
                    import {ReactComponent as GreenNiceraguaM} from '../../FoldersFirstLandingPage/Images/new texts/mobiles/Casino-Nicaragua.svg'
            //SPORT        
                    //DOLARS
                    import {ReactComponent as GreenDolarsMSport} from '../../FoldersFirstLandingPage/Images/new texts/mobiles/sport_dolars2.svg'
                     //detoque2
                    import {ReactComponent as GreenDolarsMSportNew} from '../../FoldersFirstLandingPage/Images/new texts/newHeaders/mobile/detoque2-dolars.svg'
                    //detque3 
                    import {ReactComponent as GreenDolarsMSportNewDet3} from '../../FoldersFirstLandingPage/Images/new texts/newHeaders/mobile/detoque3-dolars.svg'

                    //detoque5
                     import {ReactComponent as GreenDolarsMSportNewDet5} from '../../FoldersFirstLandingPage/Images/new texts/newHeaders/mobile/detoque5-dolars.svg'
                     //detoque6
                     import {ReactComponent as GreenDolarsMSportNewDet6} from '../../FoldersFirstLandingPage/Images/new texts/newHeaders/mobile/detoque6-dolars.svg'
                    
                    //GUATEMALA
                    import {ReactComponent as GreenGuatemalaMSport} from '../../FoldersFirstLandingPage/Images/new texts/mobiles/sport_guatemala.svg'
                     //detoque2
                    import {ReactComponent as GreenGuatemalaMSportNew} from '../../FoldersFirstLandingPage/Images/new texts/newHeaders/mobile/Guatemala-detoque2.svg'
                    //detoque3
                    import {ReactComponent as GreenGuatemalaMSportNew3Det} from '../../FoldersFirstLandingPage/Images/new texts/newHeaders/mobile/Guatemala-detoque3.svg'
                    //detoque5
                    import {ReactComponent as GreenGuatemalaMSportNew5Det} from '../../FoldersFirstLandingPage/Images/new texts/newHeaders/mobile/Guatemala-detoque5.svg'
                    //detoque6
                    import {ReactComponent as GreenGuatemalaMSportNew6Det} from '../../FoldersFirstLandingPage/Images/new texts/newHeaders/mobile/Guatemala-detoque6.svg'

                    //HONDURAS
                    import {ReactComponent as GreenHondurasMSport} from '../../FoldersFirstLandingPage/Images/new texts/mobiles/sport_honduras.svg'
                     //detoque2
                     import {ReactComponent as GreenHondurasMSportNew} from '../../FoldersFirstLandingPage/Images/new texts/newHeaders/mobile/Honduras-detoque2.svg'
                    //detoque3
                    import {ReactComponent as GreenHondurasMSportNew3} from '../../FoldersFirstLandingPage/Images/new texts/newHeaders/mobile/Honduras-detoque3.svg'
                     //detoque5
                    import {ReactComponent as GreenHondurasMSportNew5} from '../../FoldersFirstLandingPage/Images/new texts/newHeaders/mobile/Honduras-detoque5.svg'
                    //detoque6
                    import {ReactComponent as GreenHondurasMSportNew6} from '../../FoldersFirstLandingPage/Images/new texts/newHeaders/mobile/Honduras-detoque6.svg'

                     //NICARAGUA
                    import {ReactComponent as GreenNiceraguaMSport} from '../../FoldersFirstLandingPage/Images/new texts/mobiles/Sport - Nicaragua.svg'
import PopUp from './PopUp';


function BannerContent({dale, pageId, secondIndicator, thirdIndicator,main, tracker, fourthIndicator, pageType , structure,text_indicator}) {
    const [isScrolled, setIsScrolled] = useState(false);

    const [country, setCountry] = useState("");

    const location = useGeolocation()

    async function getCountryFromCoordinates(latitude, longitude) {
        const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
        
        try {
            const response = await fetch(apiUrl);
            const data = await response.json();
            
            if (data && data.address && data.address.country) {
                return data.address.country;
            } else {
                return "Unknown";
            }
        } catch (error) {
            console.error("Error fetching country:", error);
            return "Unknown";
        }
    }
   
    useEffect(() => {
        if (location.latitude && location.longitude) {
            const { latitude, longitude } = location;
            getCountryFromCoordinates(latitude, longitude)
                .then(country => {
                    setCountry(country);
                    // Do something with the country information
                })
                .catch(error => {
                    console.error("Error getting country:", error);
                });
        }
    }, [location.latitude, location.longitude]);
    
    
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);   

  const popupRef = useRef(null);

  const handleClosePopup = () => {
      if (popupRef.current) {
          popupRef.current.close();
      }
  };

  return (
    <div id="banner-content-initial-wrapper">
      
        <div id="banner-content-header"
        style={dale==="1" ? {background: 'radial-gradient(40.48% 71.46% at 50% 50%, #0010C2 0%, #001E75 100%)'} : {background: 'transperant'}}
        className={isScrolled ? 'scrolled' : ''}>
                <div id="banner-content-header-inner-wrapper">
                        {dale==="1" ?
                        <div id="banner-content-logo-wrapper-dale"
                        >
                                <img src={DaleLogo} alt='logo'/>
                        </div>
                        :
                            <div id="banner-content-logo-wrapper"
                            // onClick={() => window.location.href = 'https://detoque.com/casino/home'}
                        >
                            
                            {/* {fourthIndicator==="detoque7" ? <Popup
                                ref={popupRef}
                                trigger={<img 
                                    src={Logo} 
                                    alt='logo'
                                    />}
                                modal
                                nested
                                closeOnDocumentClick={false}
                            > 
                                <div id='nested-popup'> 
                                        <div id='title-sign'>
                                            SIGN UP NOW
                                        </div>
                                        <div id='close-nested-popup' onClick={handleClosePopup}>
                                            <img src={CloseBtn} alt='close=btn'/>
                                        </div>
                                </div>
                                <PopUp couponCodeFromParent='BNV225D'/>
                                <div id='nested-popup-footer'>
                                    <div id='nested-popup-footer-text-wrapper'>
                                        By proceeding you confirm you are 18 years of age or older.
                                    </div>
                                </div>
                            </Popup> :  */}
                            <img 
                            src={Logo} 
                            alt='logo'
                            />
                            {/* }   */}
                        </div>}
                        <div id="banner-content-btn-wrapper"
                        >
                            <div id="banner-content-left-bth-wrapper"
                            >
                                {dale==="1" ? 
                                <div id='dale-banner-register-btn' >
                                    REGISTRO
                                </div>
                            :   
                                <div
                                        id=
                                        'banner-register-btn' 
                                        style={tracker === "false10" || tracker === "false11"  ? {display: 'flex'} : {display: 'none'}}
                                        onClick={() => {
                                            const trackerURL = tracker === "true" ? 'https://detoque.com/en/homepage?register=true&btag=s_lpryqmnaygfrlbrxeovgpcypn&affid=63967&campaign_id=1740&utm_source=deposita' 
                                             : tracker === "false2" ? "https://go.aff.detoqueaffiliates.com/de4ap16u?campaign_id=2793&utm_campaign=fbbh-test&utm_source=meta":
                                              tracker === "false10" ? 'https://trk.myperfect2give.com/417289e5-d82b-4439-aaf6-45ef682ba45c?zoneid={zoneid}&campaignid={campaignid}&bannerid={bannerid}&zone_type={zone_type}&user_activity={user_activity}&subzone_id={subzone_id}&cost={cost}&visitor_id=${SUBID}' :
                                              tracker === "false11" ? 'https://go.aff.detoqueaffiliates.com/izgf6bj2&afp={clickid}&afp1=26' 
                                              : tracker === "false1" ? "https://go.aff.detoqueaffiliates.com/c9798cz5?campaign_id=2793&utm_source=casino"
                                              : 'https://detoque.com/index/register';
                                            window.location.href = trackerURL;
                                          }}
                                    >
                                            ACTIVAR
                                    </div> 
                                }
                            </div>
                        </div>
                </div>
        </div>
        <div 
        style={structure==='detoque-new' ? {marginTop: '4%', height: '90%'} : {marginTop: '11%'}}
       id={
       "banner-content-vis-elements" 
    }
        >
                {/* NORMAL VIEW */}
                    <div id="banner-content-vis-elements-inner-wrapper"
                    style={
                     thirdIndicator==="detoque1" && structure!=="detoque-new" ? {marginRight: "12%"}
                     :   thirdIndicator==="detoque1" && structure==="detoque-new" ? {marginRight: "0%"}
                    : {marginLeft: '0%'}}
                    >
                        <div id="banner-content-vis-elements-secondPart-external-wrapper"
                        style={thirdIndicator==="detoque1" && structure!=="detoque-new" ? {marginLeft: '1%'}
                        : thirdIndicator==="detoque1" && structure==="detoque-new" ? {marginLeft: '3.5%', height: '100%'}
                        : thirdIndicator==="detoque2" ? {marginLeft: '2.5%', paddingLeft: '3.2%', height: '100%'}
                        : thirdIndicator==="detoque3" ? {marginLeft: '1.5%', height: '100%'}
                        : thirdIndicator==="detoque4" ? {marginLeft: '-3.5%', height: '100%'}
                        : thirdIndicator==="detoque5" ? {marginLeft: '0%', height: '100%'}
                        : thirdIndicator==="detoque6" ? {marginLeft: '0%',  height: '100%'}
                        : {marginLeft: '-1%'}
                        }
                        >
                            <div id="banner-content-vis-elements-secondPart-row1"
                            style={structure==='detoque-new' ? { height: '75%'} : {flexDirection: 'column'}}
                            >
                                
                               
                            {dale === "1" ? (
                                    <BannerDale 
                                    style={{height: '85%'}}
                                    />
                                ) : (
                                // Render different components based on conditions
                                (pageId === 'detoque1' && secondIndicator === "detoque1" && thirdIndicator === "detoque1" && structure!=="detoque-new") ? (
                                    country === "Honduras" ? (<GreenHondurasSport style={{ height: '84%' }} />) :
                                    country === "Guatemala" ? (<GreenGuatemalaSport style={{ height: '84%' }} />) :
                                    country === "Nicaragua" ? (<GreenNiceragua style={{ height: '84%' }} />) :
                                    (<GreenDolarsSport style={{ height: '84%', width: '90%' }} />)
                                ) : (pageId === 'detoque1' && secondIndicator === "detoque1" && thirdIndicator === "detoque1" && structure==="detoque-new") ? (
                                    country === "Honduras" ? (<GreenHondurasSport style={{ height: '84%' }} />) :
                                    country === "Guatemala" ? (<GreenGuatemalaSport style={{ height: '84%' }} />) :
                                    country === "Nicaragua" ? (<GreenNiceragua style={{ height: '84%' }} />) :
                                    (<GreenDolarsMSportNew3 style={{ height: '84%', width: '90%' }} />)
                                ): (pageId === 'detoque2' && secondIndicator === "detoque2" && thirdIndicator === "detoque2") ? (
                                    // country === "Honduras" ? (<GreenHondurasSport style={{ height: '84%' }} />) :
                                    country === "Honduras" ? (<GreenHondurasSportNew style={{ height: '84%' }}/>) :
                                    // country === "Guatemala" ? (<GreenGuatemalaSport style={{ height: '84%' }} />) :
                                    country === "Guatemala" ? (<GreenGuatemalaSportNew style={{ height: '84%' }} />) :
                                    country === "Nicaragua" ? (<GreenNiceraguaSport style={{ height: '84%' }} />) :
                                    (
                                    // <GreenDolarsSport style={{ height: '84%', width: '90%' }} />
                                    <GreenDolarsSportNew style={{ height: '84%', width: '90%' }} />
                                    )
                                ) : (pageId === 'detoque1' && secondIndicator === "detoque3" && thirdIndicator === "detoque3") ? (
                                    // country === "Honduras" ? (<GreenHondurasSport style={{ height: '84%' }} />) :
                                    country === "Honduras" ? (<GreenHondurasSportNew3 style={{ height: '84%' }} />) :
                                    // country === "Guatemala" ? (<GreenGuatemalaSport style={{ height: '84%' }} />) :
                                    country === "Guatemala" ? (<GreenGuatemalaSportNew3 style={{ height: '84%' }} />) :
                                    country === "Nicaragua" ? (<GreenNiceragua style={{ height: '84%' }} />) :
                                    // (<GreenDolarsSport style={{ height: '84%', width: '90%' }} />)
                                    (<GreenDolarsMSportNew3 style={{ height: '84%', width: '90%' }} />)
                                ) : (pageId === 'detoque2' && secondIndicator === "detoque2" && thirdIndicator === "detoque4") ? (
                                    country === "Honduras" ? (<GreenHondurasSport style={{ height: '84%' }} />) :
                                    country === "Guatemala" ? (<GreenGuatemalaSport style={{ height: '84%' }} />) :
                                    country === "Nicaragua" ? (<GreenNiceragua style={{ height: '84%' }} />) :
                                    (<GreenDolarsSport style={{ height: '84%', width: '90%' }} />)
                                ) : (pageId === 'detoque1' && secondIndicator === "detoque3" && thirdIndicator === "detoque5") ? (
                                    // country === "Honduras" ? (<GreenHondurasSport style={{ height: '84%' }} />) :
                                    country === "Honduras" ? (<GreenHondurasSportNew5 style={{ height: '84%' }} />) :
                                    // country === "Guatemala" ? (<GreenGuatemalaSport style={{ height: '84%' }} />) :
                                    country === "Guatemala" ? (<GreenGuatemalaSportNew5 style={{ height: '84%' }} />) :
                                    country === "Nicaragua" ? (<GreenNiceraguaSport style={{ height: '84%' }} />) :
                                    (
                                    // <GreenDolarsSport style={{ height: '84%', width: '90%' }} />
                                    <GreenDolarsMSportNew5 style={{ height: '84%', width: '90%' }} />
                                     )
                                ) : (pageId === 'detoque2' && secondIndicator === "detoque2" && thirdIndicator === "detoque6") ? (
                                    // country === "Honduras" ? (<GreenHondurasSport style={{ height: '84%' }} />) :
                                    country === "Honduras" ? (<GreenHondurasSportNew6 style={{ height: '84%' }} />) :
                                    // country === "Guatemala" ? (<GreenGuatemalaSport style={{ height: '84%' }} />) :
                                    country === "Guatemala" ? (<GreenGuatemalaSportNew6 style={{ height: '84%' }} />) :

                                    country === "Nicaragua" ? (<GreenNiceraguaSport style={{ height: '84%' }} />) :
                                    <GreenDolarsMSportNew6 style={{ height: '84%', width: '90%' }} />
                                ) : (
                                    <div></div>
                                )
                                )}
    

                            </div>
                            <div id="banner-content-vis-elements-secondPart-row3"
                            style={structure==='detoque-new' ? {justifyContent: 'flex-start', marginLeft: '10%' , width: '50%' , alignItems: 'flex-start'} : {justifyContent: 'center'}}
                            >
                                {fourthIndicator==="detoque7" ? <Popup
                                ref={popupRef}
                                trigger={ <a 
                                    id=
                                    'banner-content-vis-elements-secondPart-btn' 
                                      style={structure==="detoque-new" ? {backgroundImage: 'linear-gradient(90deg , #FFCB27 , #F46100)', width: '72%', height: '42%', fontSize: '1.4vw'}:{ backgroundImage: ' linear-gradient(90deg , #26F596, #0499F2)'} }
                                    >
                                          {structure==='detoque-new' ? 
                                        (text_indicator==='det2' ?  'ÚNETE A LA ACCIÓN' 
                                        : text_indicator==='det3' ? 'RECLAMAR AQUÍ'
                                        : text_indicator==='det5' ? 'RECLAMAR BONO'
                                        : ' ACTIVAR AQUÍ')  
                                        : 'DEPOSITA'}
                                    </a>}
                                modal
                                nested
                                closeOnDocumentClick={false}
                            > 
                                <div id='nested-popup'> 
                                        <div id='title-sign'>
                                            SIGN UP NOW
                                        </div>
                                        <div id='close-nested-popup' onClick={handleClosePopup}>
                                            <img src={CloseBtn} alt='close=btn'/>
                                        </div>
                                </div>
                                <PopUp couponCodeFromParent='BNV225D'/>
                                <div id='nested-popup-footer'>
                                    <div id='nested-popup-footer-text-wrapper'>
                                        By proceeding you confirm you are 18 years of age or older.
                                    </div>
                                </div>
                            </Popup> : 
                             <a 
                             id=
                             'banner-content-vis-elements-secondPart-btn' 
                             href={(() => {
                                 const trackerURL =
                                     tracker === "true"
                                         ? 'https://detoque.com/en/homepage?register=true&btag=s_lpryqmnaygfrlbrxeovgpcypn&affid=63967&campaign_id=1740&utm_source=deposita'
                                         : tracker === "false2"
                                         ? "https://go.aff.detoqueaffiliates.com/tc8zatq2?campaign_id=2827&utm_source=sport"
                                         : tracker === "false2.1"
                                         ? "https://go.aff.detoqueaffiliates.com/rxvmyugu?campaign_id=2793&utm_source=sport"
                                         : tracker === "false10"
                                         ? 'https://trk.myperfect2give.com/417289e5-d82b-4439-aaf6-45ef682ba45c?zoneid={zoneid}&campaignid={campaignid}&bannerid={bannerid}&zone_type={zone_type}&user_activity={user_activity}&subzone_id={subzone_id}&cost={cost}&visitor_id=${SUBID}'
                                         : tracker === "false11"
                                         ? 'https://go.aff.detoqueaffiliates.com/59tm0924&afp={clickid}&afp1=26'
                                         : tracker === "false1"
                                         ? "https://go.aff.detoqueaffiliates.com/c9798cz5?campaign_id=2793&utm_source=casino"
                                         : tracker === "false1.1"
                                         ? "https://go.aff.detoqueaffiliates.com/mwrrwouc?campaign_id=2827&utm_source=casino"
                                         : 'https://detoque.com/index/register';
                                 return trackerURL;
                             })()}
                               style={structure==="detoque-new" ? {backgroundImage: 'linear-gradient(90deg , #FFCB27 , #F46100)', width: '72%', height: '42%', fontSize: '1.4vw'}:{ backgroundImage: ' linear-gradient(90deg , #26F596, #0499F2)'} }
                             >
                                   {structure==='detoque-new' ? 
                                 (text_indicator==='det2' ?  'ÚNETE A LA ACCIÓN' 
                                 : text_indicator==='det3' ? 'RECLAMAR AQUÍ'
                                 : text_indicator==='det5' ? 'RECLAMAR BONO'
                                 : ' ACTIVAR AQUÍ')  
                                 : 'DEPOSITA'}
                             </a>
                            }
                               
                                
                            </div>
                        </div>
                    </div>
                    <div id="banner-content-vis-elements-inner-wrapper-over-the-figure"
                    >
                    </div>
                {/* MOBILE VIEW */}
                <div id="banner-content-vis-elements-inner-wrapper-mobile">
                            <div id="banner-content-mobile-text-wrapper"
                                style={
                                    
                                    //SECOND PAGE - SPORT
                                    thirdIndicator==="detoque1" && structure==="detoque-new" ? {height:'34%' ,marginTop: '18%'} 
                                    : thirdIndicator==="detoque2" ? {height:'29%' ,marginTop: '20%'} 
                                    : thirdIndicator==="detoque1" ?  {height: '22%', marginTop: '18%'}
                                    : thirdIndicator==='detoque3' ? {height:'35%', marginTop: '15%'}
                                    : thirdIndicator==='detoque4' ? {height:'21%'}
                                    : thirdIndicator==='detoque5' ? {height:'32%', marginTop: '20%'}
                                    : thirdIndicator==='detoque6' ? {height:'36%', marginTop: '20%'}
                                    :
                                    {height: '22%', marginTop: '15%'}}
                            > 
                               {dale === '1' ? (
                                    <BannerDaleMobile/>
                                    ) : (
                                    country === "Honduras" ? (
                                        thirdIndicator === 'detoque1' || thirdIndicator === 'detoque3' || thirdIndicator === 'detoque4' ?
                                        <GreenHondurasMSport /> : <GreenHondurasMSport />
                                    )
                                    (
                                        thirdIndicator === 'detoque1'   && structure!=="detoque-new" ?  <GreenHondurasMSport /> 
                                        : thirdIndicator === 'detoque1' && structure==="detoque-new" ? <GreenHondurasMSportNew3 />
                                        : thirdIndicator === 'detoque4' ?    <GreenHondurasMSport /> 
                                        : thirdIndicator === 'detoque2' ? <GreenHondurasMSportNew />
                                        : thirdIndicator === 'detoque3' ? <GreenHondurasMSportNew3 />
                                        : thirdIndicator === 'detoque5' ? <GreenHondurasMSportNew5 />
                                        : thirdIndicator === 'detoque6' ? <GreenHondurasMSportNew6 />

                                        : <GreenHondurasMSport />
                                    )
                                     : country === "Guatemala" ? (
                                        thirdIndicator === 'detoque1'  && structure!=="detoque-new" ? <GreenGuatemalaMSport /> 
                                        : thirdIndicator === 'detoque1' && structure==="detoque-new" ? <GreenGuatemalaMSportNew3Det />
                                        : thirdIndicator === 'detoque4' ?  <GreenGuatemalaMSport /> 
                                        : thirdIndicator === 'detoque2' ? <GreenGuatemalaMSportNew />
                                        : thirdIndicator === 'detoque3' ? <GreenGuatemalaMSportNew3Det />

                                        : thirdIndicator === 'detoque5' ? <GreenGuatemalaMSportNew5Det />
                                        : thirdIndicator === 'detoque6' ? <GreenGuatemalaMSportNew6Det />

                                        : <GreenGuatemalaMSport />
                                    ) : country === "Nicaragua" ? (
                                        thirdIndicator === 'detoque1' || thirdIndicator === 'detoque3' || thirdIndicator === 'detoque4' ?
                                        <GreenNiceraguaM /> : <GreenNiceraguaMSport />
                                    ) : (
                                        thirdIndicator === 'detoque1' && structure!=="detoque-new" ? <GreenDolarsMSport/>
                                        : thirdIndicator === 'detoque1' && structure==="detoque-new" ? <GreenDolarsMSportNewDet3/>
                                        : thirdIndicator === 'detoque4' ? <GreenDolarsMSport /> 
                                        : thirdIndicator === 'detoque2' ? <GreenDolarsMSportNew />
                                        : thirdIndicator === 'detoque3' ? <GreenDolarsMSportNewDet3 />
                                        : thirdIndicator === 'detoque5' ? <GreenDolarsMSportNewDet5 />
                                        : thirdIndicator === 'detoque6' ? <GreenDolarsMSportNewDet6 />

                                        : <GreenDolarsMSport />
                                    )
                                    )}
                                   
                            </div>
                            {fourthIndicator==="detoque7" ? <Popup
                                ref={popupRef}
                                trigger={ <a id='mobile-deposito-btn'
                                  style={structure === "detoque-new" ? 
                                  { 
                                    backgroundImage: 'linear-gradient(90deg, #FFCB27, #F46100)',
                                    ...(thirdIndicator === 'detoque2' ? { marginTop: '3%', height: '8%', width: '82%' }
                                      : thirdIndicator === 'detoque1' && structure!=="detoque-new" ? { marginTop: '5%' }
                                      : thirdIndicator === 'detoque1' && structure==="detoque-new" ? { marginTop: '2%', height: '8%', width: '82%' }
                                      :  thirdIndicator === 'detoque4' ? { marginTop: '5%' }
                                      : thirdIndicator === 'detoque3' ? { marginTop: '0%',height: '8%', width: '82%' }
                                      : thirdIndicator === 'detoque5' ? { marginTop: '1%', height: '8%', width: '82%' }
                                      : thirdIndicator === 'detoque6' ? { marginTop: '2%',height: '8%', width: '82%' }
                                      : { marginTop: '1%' }),
                                      fontSize: '6.5vw', fontWeight: '700' 
    
                                  } 
                                  : 
                                  { 
                                    backgroundImage: 'linear-gradient(90deg, #26F596, #0499F2)',
                                  }}
    
                                >
                                        {structure==='detoque-new' ? 
                                        (text_indicator==='det2' ?  'ÚNETE A LA ACCIÓN' 
                                        : text_indicator==='det3' ? 'RECLAMAR AQUÍ'
                                        : text_indicator==='det5' ? 'RECLAMAR BONO'
                                        : ' ACTIVAR AQUÍ')  
                                        : 'DEPOSITA'}
                                </a> }
                                modal
                                nested
                                closeOnDocumentClick={false}
                            > 
                                <div id='nested-popup'> 
                                        <div id='title-sign'>
                                            SIGN UP NOW
                                        </div>
                                        <div id='close-nested-popup' onClick={handleClosePopup}>
                                            <img src={CloseBtn} alt='close=btn'/>
                                        </div>
                                </div>
                                <PopUp couponCodeFromParent='BNV225D'/>
                                <div id='nested-popup-footer'>
                                    <div id='nested-popup-footer-text-wrapper'>
                                        By proceeding you confirm you are 18 years of age or older.
                                    </div>
                                </div>
                            </Popup>

                        :    
                            <a id='mobile-deposito-btn'
                            href={(() => {
                                const trackerURL =
                                    tracker === "true"
                                        ? 'https://detoque.com/en/homepage?register=true&btag=s_lpryqmnaygfrlbrxeovgpcypn&affid=63967&campaign_id=1740&utm_source=deposita'
                                        : tracker === "false2"
                                        ? "https://go.aff.detoqueaffiliates.com/tc8zatq2?campaign_id=2827&utm_source=sport"
                                        : tracker === "false2.1"
                                        ? "https://go.aff.detoqueaffiliates.com/rxvmyugu?campaign_id=2793&utm_source=sport"
                                        : tracker === "false10"
                                        ? 'https://trk.myperfect2give.com/417289e5-d82b-4439-aaf6-45ef682ba45c?zoneid={zoneid}&campaignid={campaignid}&bannerid={bannerid}&zone_type={zone_type}&user_activity={user_activity}&subzone_id={subzone_id}&cost={cost}&visitor_id=${SUBID}'
                                        : tracker === "false11"
                                        ? 'https://go.aff.detoqueaffiliates.com/59tm0924&afp={clickid}&afp1=26'
                                        : tracker === "false1"
                                        ? "https://go.aff.detoqueaffiliates.com/c9798cz5?campaign_id=2793&utm_source=casino"
                                        : tracker === "false1.1"
                                        ? "https://go.aff.detoqueaffiliates.com/mwrrwouc?campaign_id=2827&utm_source=casino"
                                        : 'https://detoque.com/index/register';
                                return trackerURL;
                            })()}
                              style={structure === "detoque-new" ? 
                              { 
                                backgroundImage: 'linear-gradient(90deg, #FFCB27, #F46100)',
                                ...(thirdIndicator === 'detoque2' ? { marginTop: '3%', height: '8%', width: '82%' }
                                  : thirdIndicator === 'detoque1' && structure!=="detoque-new" ? { marginTop: '5%' }
                                  : thirdIndicator === 'detoque1' && structure==="detoque-new" ? { marginTop: '2%', height: '8%', width: '82%' }
                                  :  thirdIndicator === 'detoque4' ? { marginTop: '5%' }
                                  : thirdIndicator === 'detoque3' ? { marginTop: '0%',height: '8%', width: '82%' }
                                  : thirdIndicator === 'detoque5' ? { marginTop: '1%', height: '8%', width: '82%' }
                                  : thirdIndicator === 'detoque6' ? { marginTop: '2%',height: '8%', width: '82%' }
                                  : { marginTop: '1%' }),
                                  fontSize: '6.5vw', fontWeight: '700' 

                              } 
                              : 
                              { 
                                backgroundImage: 'linear-gradient(90deg, #26F596, #0499F2)',
                              }}

                            >
                                    {structure==='detoque-new' ? 
                                    (text_indicator==='det2' ?  'ÚNETE A LA ACCIÓN' 
                                    : text_indicator==='det3' ? 'RECLAMAR AQUÍ'
                                    : text_indicator==='det5' ? 'RECLAMAR BONO'
                                    : ' ACTIVAR AQUÍ')  
                                    : 'DEPOSITA'}
                            </a>
                            }
                               

                </div>
        </div>

    </div>
  )
}

export default BannerContent