// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Images/Banner-SVG.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Images/Banner Mobile-SVG.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#initial-page-wrapper{
    height: 142vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: rgb(38, 38, 37);
}



/* MAIN BANNER */
#page-header-banner1{
    height: 57.3%;
    width: 100%;
    /* margin-bottom: 2%;     */
    /* background-image: url('./Images/Banner.png'); */
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
}

@media (max-width: 767px) {
    #page-header-banner1 {
      /* background-image: url('./Images/Banner Mobile.png'); */
      background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
      /* Adjust background properties for mobile */
    }
  }



#page-header-banner-2{
    height: 42.7%;
    width: 100%;
    background-color: #0a0505;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 2%;     */
}

@media (max-width: 767px) {
    #initial-page-wrapper{
        height: 260vh;
        background-color: #0C0C0C;
    }
    #page-header-banner1{
        height: 38.6%;
    }

    #page-header-banner-2{
        height: 61%;
    }

  }
`, "",{"version":3,"sources":["webpack://./src/FoldersFirstLandingPage/MainPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,cAAc;IACd,iCAAiC;AACrC;;;;AAIA,gBAAgB;AAChB;IACI,aAAa;IACb,WAAW;IACX,2BAA2B;IAC3B,kDAAkD;IAClD,yDAAgD;IAChD,sBAAsB;IACtB,2BAA2B;AAC/B;;AAEA;IACI;MACE,yDAAyD;MACzD,yDAAwD;MACxD,4CAA4C;IAC9C;EACF;;;;AAIF;IACI,aAAa;IACb,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,2BAA2B;AAC/B;;AAEA;IACI;QACI,aAAa;QACb,yBAAyB;IAC7B;IACA;QACI,aAAa;IACjB;;IAEA;QACI,WAAW;IACf;;EAEF","sourcesContent":["#initial-page-wrapper{\r\n    height: 142vh;\r\n    width: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    overflow: auto;\r\n    background-color: rgb(38, 38, 37);\r\n}\r\n\r\n\r\n\r\n/* MAIN BANNER */\r\n#page-header-banner1{\r\n    height: 57.3%;\r\n    width: 100%;\r\n    /* margin-bottom: 2%;     */\r\n    /* background-image: url('./Images/Banner.png'); */\r\n    background-image: url('./Images/Banner-SVG.svg');\r\n    background-size: cover;\r\n    background-position: center;\r\n}\r\n\r\n@media (max-width: 767px) {\r\n    #page-header-banner1 {\r\n      /* background-image: url('./Images/Banner Mobile.png'); */\r\n      background-image: url('./Images/Banner\\ Mobile-SVG.svg');\r\n      /* Adjust background properties for mobile */\r\n    }\r\n  }\r\n\r\n\r\n\r\n#page-header-banner-2{\r\n    height: 42.7%;\r\n    width: 100%;\r\n    background-color: #0a0505;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    /* margin-bottom: 2%;     */\r\n}\r\n\r\n@media (max-width: 767px) {\r\n    #initial-page-wrapper{\r\n        height: 260vh;\r\n        background-color: #0C0C0C;\r\n    }\r\n    #page-header-banner1{\r\n        height: 38.6%;\r\n    }\r\n\r\n    #page-header-banner-2{\r\n        height: 61%;\r\n    }\r\n\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
