import React, { useState } from 'react';
import './Step2Component.css';

function Step2Component({onSecondStepSubmit}) {
  const [gender, setGender] = useState('male');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [country, setCountry] = useState('Guatemala'); // Default selected country
  const [currency, setCurrency] = useState('Guatemalan Quetzal'); // Default selected currency
  const [cellPhone, setCellPhone] = useState('');
  const [dayOfBirth, setDayOfBirth] = useState('');
  const [monthOfBirth, setMonthOfBirth] = useState('');
  const [yearOfBirth, setYearOfBirth] = useState('');
  const [fnameError, setFnameError] = useState('');
  const [lnameError, setLnameError] = useState('');
  const [cellPhoneError, setCellPhoneError] = useState('');
  const [dayOfBirthError, setDayOfBirthError] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false); 
  const [receiveNewsletters, setReceiveNewsletters] = useState(true); 

  // Countries, currencies, and phone codes
  const countries = ["Guatemala", "Honduras"];
  const currencies = ["Guatemalan Quetzal", "Honduran Lempira", "United States Dollar"];
  const phoneCodes = ["+502 Guatemala", "+504 Honduras"];

  const handleSubmit = () => {
    // Reset previous errors
    setFnameError('');
    setLnameError('');
    setCellPhoneError('');
    setDayOfBirthError('');
  
    // Validation
    let valid = true;
  
    if (fname.trim() === '') {
      setFnameError('First name is required');
      valid = false;
    } else if (/\d/.test(fname)) {
      setFnameError('First name cannot contain digits');
      valid = false;
    }
  
    if (lname.trim() === '') {
      setLnameError('Last name is required');
      valid = false;
    } else if (/\d/.test(lname)) {
      setLnameError('Last name cannot contain digits');
      valid = false;
    }
  
    const phoneRegex = /^(\+(?:[0-9] ?){6,14}[0-9]|[\d]{7,15})$/;
    if (!phoneRegex.test(cellPhone.trim())) {
      setCellPhoneError('Invalid cell phone number');
      valid = false;
    }
  
    if (dayOfBirth.trim() === '') {
      setDayOfBirthError('Day of birth is required');
      valid = false;
    }
  
    if (!acceptTerms) {
      valid = false;
    }
  
    let selectedDate = new Date(`${yearOfBirth}-${monthOfBirth}-${dayOfBirth}`);
    let currentDate = new Date();
    let age = currentDate.getFullYear() - selectedDate.getFullYear();
    let monthDifference = currentDate.getMonth() - selectedDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && currentDate.getDate() < selectedDate.getDate())) {
      age--;
    }
  
    if (age < 18) {
      setDayOfBirthError('You must be at least 18 years old');
      valid = false;
    }
  
    // If all fields are valid, proceed
    if (valid) {
      onSecondStepSubmit(
        gender,
        fname,
        lname,
        country,
        currency,
        cellPhone,
        `${yearOfBirth}-${monthOfBirth}-${dayOfBirth}`,
        receiveNewsletters
      );
    }
  };
  

  const getPhoneCode = () => {
    if (country === "Guatemala") {
      return "+502";
    } else if (country === "Honduras") {
      return "+504";
    }
    return ""; // default empty string
  };

  return (
    <div id='step-two-wrapper'>
      <div id="gender-selector">
        <input
          type='radio'
          id='male-radio'
          name='gender'
          value='male'
          checked={gender === 'male'}
          onChange={() => setGender('male')}
        />
        <label htmlFor='male-radio' id='label-gender'>
          Male
        </label>
        <input
          type='radio'
          id='female-radio'
          name='gender'
          value='female'
          checked={gender === 'female'}
          onChange={() => setGender('female')}
        />
        <label htmlFor='female-radio' id='label-gender'>
          Female
        </label>
      </div>
      <div id='input-wrapper-step2'>
        <label id='input-title'>First Name (same as your ID)</label>
        <input
          id='input'
          placeholder='First Name (same as your ID)'
          type='text'
          value={fname}
          onChange={(e) => setFname(e.target.value)}
        />
        <label id='input-error-prompt'>{fnameError}</label>
      </div>
      <div id='input-wrapper-step2'>
        <label id='input-title'>Last Name (same as your ID)</label>
        <input
          id='input'
          placeholder='Last Name (same as your ID)'
          type='text'
          value={lname}
          onChange={(e) => setLname(e.target.value)}
        />
        <label id='input-error-prompt'>{lnameError}</label>
      </div>
      <div id='input-wrapper-step2-country-curr'>
        <div id='input-wrapper-step2-country'>
            <label
            style={{paddingLeft: '6%'}}
            id='input-title'>Country</label>
            <select
            id='country-select'
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            >
                {countries.map((country, index) => (
                    <option key={index} value={country}>{country}</option>
                ))}
            </select>
        </div>
        <div id='input-wrapper-step2-country'>
            <label id='input-title'
            style={{paddingLeft: '6%'}}
            >Currency</label>
            <select
            id='currency-select'
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
            >
                {currencies.map((currency, index) => (
                    <option key={index} value={currency}>{currency}</option>
                ))}
            </select>
        </div>
      </div>
      <div id='input-wrapper-step2'>
        <label id='input-title'>Cell Phone</label>
        <input
          id='input'
          // placeholder='Cell Phone'
          placeholder={`Cell Phone (${getPhoneCode()})`}
          type='text'
          value={cellPhone}
          onChange={(e) => setCellPhone(e.target.value)}
        />
        <label id='input-error-prompt'>{cellPhoneError}</label>
      </div>
      <div id='input-wrapper-step2'>
        <label id='input-title'>Date of Birth</label>
        <div id='date-birthselectors-wrapper'>
            <select
            id='day-select'
            value={dayOfBirth}
            onChange={(e) => setDayOfBirth(e.target.value)}
            >
                <option value="">Day</option>
                {[...Array(31).keys()].map(day => (
                    <option key={day + 1} value={day + 1}>{day + 1}</option>
                ))}
            </select>
            <select
            id='month-select'
            value={monthOfBirth}
            onChange={(e) => setMonthOfBirth(e.target.value)}
            >
                <option value="">Month</option>
                {[...Array(12).keys()].map(month => (
                    <option key={month + 1} value={month + 1}>{month + 1}</option>
                ))}
            </select>
            <select
            id='year-select'
            value={yearOfBirth}
            onChange={(e) => setYearOfBirth(e.target.value)}
            >
                <option value="">Year</option>
                {[...Array(60).keys()].map(year => (
                    <option key={2006 - year} value={2006 - year}>{2006 - year}</option>
                ))}
            </select>
        </div>
        
        <label id='input-error-prompt'>{dayOfBirthError}</label>
      </div>
      <div id='policies-field'>
        {/* Terms and conditions checkbox */}
        <div id='policy-line'>
          <input
            type='checkbox'
            id='policies-checkbox'
            checked={acceptTerms}
            onChange={() => setAcceptTerms(!acceptTerms)}
          />
          <label id='policies-label'>
            By clicking "Create New Account" I confirm that I read and accept the <a href='#'>Terms and Conditions</a> and the <a href='#'>Privacy Policy</a>
          </label>
        </div>
        {/* Newsletters checkbox */}
        <div id='policy-line'>
          <input
            type='checkbox'
            id='newsletter-checkbox'
            checked={receiveNewsletters}
            onChange={() => setReceiveNewsletters(!receiveNewsletters)}
          />
          <label id='policies-label'>
            Send me Newsletters and SMS
          </label>
        </div>
      </div>
      <div id='input-wrapper-btn2'>
        <button onClick={handleSubmit}>Create new account</button>
      </div>
    </div>
  );
}

export default Step2Component;
