// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#popup-inner-content-wrapper{
    height: 87%;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* background-color: rgb(78, 78, 78); */
}

#steps-wrapper{
    height: 12%;
    width: 100%;
    display: flex;
    /* background-color: rgb(50, 50, 50); */
}

#step-initial-wrapper{
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#step-initial-wrapper::before{
    position: absolute;
    height: 1px;
    background-color: #4d4d4d;
    content: "";
    width: 46.6%;
    left: 26.665%;
}

#step-1-wrapper{
    width: 1.75rem;
    height: 1.75rem;
    color: #5d5d5d;
    border: 1px solid #4d4d4d;
    border-radius: 50%;
    z-index: 100;
    display: flex;
    align-items: center;
    font-weight: 700;
    background-color: #1a1a1a;
    justify-content: center;
    font-size: .75rem;
}`, "",{"version":3,"sources":["webpack://./src/FoldersFirstLandingPage/BannerContent/PopUp.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uCAAuC;AAC3C;;AAEA;IACI,WAAW;IACX,WAAW;IACX,aAAa;IACb,uCAAuC;AAC3C;;AAEA;IACI,YAAY;IACZ,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IAGZ,aAAa;IAGb,mBAAmB;IACnB,gBAAgB;IAChB,yBAAyB;IAGzB,uBAAuB;IACvB,iBAAiB;AACrB","sourcesContent":["#popup-inner-content-wrapper{\r\n    height: 87%;\r\n    width: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    /* background-color: rgb(78, 78, 78); */\r\n}\r\n\r\n#steps-wrapper{\r\n    height: 12%;\r\n    width: 100%;\r\n    display: flex;\r\n    /* background-color: rgb(50, 50, 50); */\r\n}\r\n\r\n#step-initial-wrapper{\r\n    height: 100%;\r\n    width: 50%;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n#step-initial-wrapper::before{\r\n    position: absolute;\r\n    height: 1px;\r\n    background-color: #4d4d4d;\r\n    content: \"\";\r\n    width: 46.6%;\r\n    left: 26.665%;\r\n}\r\n\r\n#step-1-wrapper{\r\n    width: 1.75rem;\r\n    height: 1.75rem;\r\n    color: #5d5d5d;\r\n    border: 1px solid #4d4d4d;\r\n    border-radius: 50%;\r\n    z-index: 100;\r\n    display: -webkit-box;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    -webkit-box-align: center;\r\n    -ms-flex-align: center;\r\n    align-items: center;\r\n    font-weight: 700;\r\n    background-color: #1a1a1a;\r\n    -webkit-box-pack: center;\r\n    -ms-flex-pack: center;\r\n    justify-content: center;\r\n    font-size: .75rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
