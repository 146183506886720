import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import Routes instead of Switch
import MainPage from './FoldersFirstLandingPage/MainPage';
import MainPage2 from './FoldersSecondLandingPage/MainPage2';
import MainPage3 from './FoldersThirdLandingPage/MainPage3';
import MainPage4 from './FoldersFourthLandingPage/MainPage4';
import MainPage5 from './FoldersFifthLandingPage/MainPage5';
import MainPage6 from './FoldersSixthLandingPage/MainPage6';
import MainPage7 from './FoldersSeventhLandingPage/MainPage7';
import MainPage8 from './FoldersEightLandingPage/MainPage8';
// import MainPageDale1 from './Dale/DaleFirstPage/MainPageDale1';
import MainPage9 from './FoldersNinthLandingPage/MainPage9';
import MainPage10 from './FoldersTenthLandingPage/MainPage10';
import MainPage11 from './FoldersEleventhLandingPage/MainPage11';
import MainPage1_1 from './FoldersFirstLandingPage/MainPage1_1';
import MainPage3_1 from './FoldersThirdLandingPage/MainPage3_1';
import MainPage2_1 from './FoldersSecondLandingPage/MainPage2_1';
import MainPage5_1 from './FoldersFifthLandingPage/MainPage5_1';
import MainPage1_2 from './FoldersFirstLandingPage/MainPage1_2';
import MainPage1_3 from './FoldersFirstLandingPage/MainPage1_3';
import MainPage3_2 from './FoldersThirdLandingPage/MainPage3_2';
import MainPage3_3 from './FoldersThirdLandingPage/MainPage3_3';
import MainPage2_2 from './FoldersSecondLandingPage/MainPage2_2';
import MainPage2_3 from './FoldersSecondLandingPage/MainPage2_3';
import MainPage5_2 from './FoldersFifthLandingPage/MainPage5_2';
import MainPage5_3 from './FoldersFifthLandingPage/MainPage5_3';

function App() {
  return (
    <div className="App">
        <Router>
            <Routes> {/* Use Routes instead of Switch */}
              <Route path="/detoque1" element={<MainPage />} />
              {/* detoque1.1 */}
              <Route path="/detoque1.1" element={<MainPage1_1/>}/>
              <Route path="/detoque1.2" element={<MainPage1_2/>}/>
              <Route path="/detoque1.3" element={<MainPage1_3/>}/>
              <Route path="/detoque2"  element={<MainPage2/>} />
              {/* detoque2.1 */}
              <Route path="/detoque2.1" element={<MainPage2_1/>}/>
              <Route path="/detoque2.2" element={<MainPage2_2/>}/>
              <Route path="/detoque2.3" element={<MainPage2_3/>}/>
              <Route path="/detoque3" element={<MainPage3/>} />
              {/* detoque3.1 */}
              <Route path="/detoque3.1" element={<MainPage3_1/>}/>
              <Route path="/detoque3.2" element={<MainPage3_2/>}/>
              <Route path="/detoque3.3" element={<MainPage3_3/>}/>

              <Route path="/detoque4" element={<MainPage4/>}/>
              <Route path="/detoque5" element={<MainPage5/>} />
              {/* detoque5.1 */}
              <Route path="/detoque5.1" element={<MainPage5_1/>}/>
              <Route path="/detoque5.2" element={<MainPage5_2/>}/>
              <Route path="/detoque5.3" element={<MainPage5_3/>}/>
              <Route path='/detoque6' element={<MainPage6/>}/>
              <Route path='/detoque7' element={<MainPage7/>}/>
              <Route path='/detoque8' element={<MainPage8/>}/>
              <Route path='/detoque9' element={<MainPage9/>}/>
              <Route path='/detoque10' element={<MainPage10/>}/>
              <Route path='/detoque11' element={<MainPage11/>}/>
              {/* <Route path='/dale1' element={<MainPageDale1/>}/> */}
            </Routes>
          </Router>
    </div>
  );
}

export default App;
